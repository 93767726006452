import React from "react";
import "../Styles/presscoverage.css";
import ReactPlayer from "react-player/youtube";
import Image from "../Images/PressCoverage/The_Hindu_Nivar.png";
import Image1 from "../Images/PressCoverage/Nivar_india_today.png";
import Image2 from "../Images/PressCoverage/tehelka_cc.png";
import Image3 from "../Images/PressCoverage/IE.png";
import Image4 from "../Images/PressCoverage/ISW.png";
import Image5 from "../Images/PressCoverage/CoE_Inauguration.png";
export default function Presscoverage() {
  return (
    <div>
      <h1 style={{ textAlign: "center", color: "#ffffff" }}>
        Videos & News articles
      </h1>
      <h1 className="header">Nivar cyclone (2020)</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer
          height={"600px"}
          width={"1380px"}
          controls={true}
          url="https://youtu.be/RLp55HCVZy4"
        />
      </div>
      <div className="presscontainer">
        <a
          href="https://www.thehindu.com/news/cities/chennai/data-collected-by-iit-madras-during-nivar-will-help-flood-forecasting/article33389041.ece"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image} alt="Herd of horses" className="imges" />
        </a>
        <a
          href="https://www.indiatoday.in/education-today/news/story/iit-madras-researchers-brave-cyclone-nivar-collect-data-prevent-future-floods-in-chennai-1751684-2020-12-21"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image1} alt="Baby Elephant" className="imges" />
        </a>
      </div>
      <h1 className="header"> About extreme rainfall events in future</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer
          height={"600px"}
          width={"1380px"}
          controls={true}
          url="https://youtu.be/QWU2mwMVRrw"
        />
      </div>
      <div className="presscontainer">
        <a
          href="http://tehelka.com/rise-in-carbon-emission-may-lead-to-more-extreme-rainfall-events-in-chennai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image2} alt="Herd of horses" className="imges" />
        </a>
        <a
          href="https://www.newindianexpress.com/cities/chennai/2020/jun/30/study-says-climate-change-may-lead-to-bigger-floods-than-2015-in-chennai-2163236.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image3} alt="Baby Elephant" className="imges" />
        </a>
        <a
          href="https://vigyanprasar.gov.in/isw/Rise-in-carbon-emission-may-lead-to-more-extreme-rainfall-events-in-Chennai.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image4} alt="Baby Elephant" className="imges" />
        </a>
      </div>
      <h1 className="header">
        The CoE - Climate Change Impacts on Coastal Infrastructure and the
        Adaptation Strategies Inauguration
      </h1>
      <div className="presscontainer">
        <a
          href="https://www.indiatoday.in/education-today/news/story/iit-madras-launches-dst-centre-of-excellence-in-climate-change-impacts-on-coastal-infrastructure-1503897-2019-04-17"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Image5} alt="Herd of horses" className="imges" />
        </a>
      </div>
    </div>
  );
}
