import React, { useState } from "react";
import "../Styles/profile.css"; // Import your styles
import person1 from "../Images/People/SA-min.jpg";
import person2 from "../Images/People/Murty-min.jpg";
import person3 from "../Images/People/sundar.jpg";
import person4 from "../Images/People/sundaravadivelu.jpg";
import person5 from "../Images/People/murali-min.jpg";
import person6 from "../Images/People/Nilanjansaha-min.jpg";
import person7 from "../Images/People/SriramVenkatachalam.jpg";
import person8 from "../Images/People/Balaji-min.jpg";
import person9 from "../Images/People/BalajiNarasimhan-min.jpg";
import person10 from "../Images/People/IndumathiNambi-min.jpg";
import person11 from "../Images/People/LigyPhilip-min.jpg";
import person12 from "../Images/People/SACHIN-min.jpg";
import person13 from "../Images/People/sudeer.jpg";
import person14 from "../Images/People/Kuiry-min.jpg";
import person15 from "../Images/People/VenkatramanSrinivasn.jpg";
import person16 from "../Images/People/SudhirChellaRajan.jpg";
import person17 from "../Images/People/SabujKumarMandal-min.jpg";
import person18 from "../Images/People/vengat.jpg";
import person19 from "../Images/People/Ashiq-min.jpg";
import person20 from "../Images/People/Bhavithra-min.jpeg";
import person21 from "../Images/People/gracy-min.jpeg";
import person22 from "../Images/People/iqbal-min.jpg";
import person23 from "../Images/People/santhoshKumar-min.jpg";
import person24 from "../Images/People/Elancheliyan-min.jpg";
import person25 from "../Images/People/Rohini-min.jpg";
import person26 from "../Images/People/Benisha-min.jpeg";
import person27 from "../Images/People/HariRam-min.jpg";
import person28 from "../Images/People/VasanthKumar.jpg";
import person29 from "../Images/People/Dhananjayan-min.jpg";
const People = () => {
  const peopleData = [
    {
      imageUrl: person1,
      // imageUrl:
      //   "https://dl.dropboxusercontent.com/s/luspdnjjohln22e/pexels-photo-59576.jpeg",
      name: "Prof.Sannasiraj S A",
      role: "Principal Investigator",
      department: "Department Of Ocean Engineering",
      institution: "Indian Institute Of Technology Madras",
      link: "http://doe.iitm.ac.in/sasraj/",
    },
    // Add more people as needed
    {
      imageUrl: person2,
      name: "Prof.Murty B S",
      role: "Centre Coordinator",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/bsm/",
    },
    // Add more people as needed
  ];

  const peopleData2 = [
    {
      imageUrl: person3,
      name: "Dr.Sundar V",
      role: "Professor Emeritus",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "http://doe.iitm.ac.in/vsundar/",
    },
    // Add more people as needed
    {
      imageUrl: person4,
      name: "Dr.Sundaravadivelu R",
      role: "Professor Emeritus",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "http://doe.iitm.ac.in/rsun/",
    },
    {
      imageUrl: person5,
      name: "Dr. Murali K",
      role: "Professor",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "http://doe.iitm.ac.in/murali/",
    },
    {
      imageUrl: person6,
      name: "Dr.Nilanjan Saha",
      role: "Professor",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "http://doe.iitm.ac.in/nilanjan/",
    },
    {
      imageUrl: person7,
      name: "Dr.SriramVenkatachalam",
      role: "Associate Professor",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://home.iitm.ac.in/vsriram/",
    },
    {
      imageUrl: person8,
      name: "Dr.Balaji C",
      role: "Professor",
      department: "Department of Mechanical Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://home.iitm.ac.in/balaji/#!/",
    },
    {
      imageUrl: person9,
      name: "Dr.Balaji Narasimhan",
      role: "Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/nbalaji/",
    },
    {
      imageUrl: person10,
      name: "Dr.Indumathi M Nambi",
      role: "Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/indumathi/",
    },
    {
      imageUrl: person11,
      name: "Dr.Ligy Philip",
      role: "Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://iitm.irins.org/profile/50483",
    },
    {
      imageUrl: person12,
      name: "Dr.Sachin S Gunthe",
      role: "Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/sgunthe/",
    },
    {
      imageUrl: person13,
      name: "Dr.Sudheer K P",
      role: "Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/sudheer/",
    },
    {
      imageUrl: person14,
      name: "Dr.SoumendraNathKuiry",
      role: "Assistant Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://civil.iitm.ac.in/faculty/snkuiry/",
    },
    {
      imageUrl: person15,
      name: "Dr.VenkatramanSrinivasan",
      role: "Assistant Professor",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
      link: "https://home.iitm.ac.in/venkatraman/Venkatraman.htm",
    },
    {
      imageUrl: person16,
      name: "Dr.Sudhir Chella Rajan",
      role: "Professor",
      department: "Department of Humanities and Social Sciences",
      institution: "Indian Institute of Technology Madras",
      link: "https://hss.iitm.ac.in/team-members/sudhir-chella-rajan/",
    },
    {
      imageUrl: person17,
      name: "Dr.SabujKumar Mandal",
      role: "Associate Professor",
      department: "Department of Humanities and Social Sciences",
      institution: "Indian Institute of Technology Madras",
      link: "https://hss.iitm.ac.in/team-members/sabuj-kumar-mandal/",
    },
    // Add more people as needed
  ];
  const peopleData3 = [
    {
      imageUrl: person18,
      name: "Vengadesan P",
      role: "Project Officer (Ph.D) & Coordinator Hydro-dynamic modelling, watershed modelling, Dam impact study",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    // Add more people as needed
    {
      imageUrl: person19,
      name: "Ashiq Ahmed M",
      role: "Project Officer (Ph.D) Hydro-dynamic modelling, GIS mapping,coastal vulnerability assessment",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person20,
      name: "Bhavithra R S",
      role: "Project Officer (Ph.D),Deep ocean wave climate modelling (WAM), wave forecasting",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person21,
      name: "Gracy Margret Mary R",
      role: "Project Officer (Ph.D),Hydro & Morpho-dynamic modelling,Machine learning application in coastal processes",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person22,
      name: "Iqbal Mohamed",
      role: "Project Officer (Ph.D),Solid and wastewater treatment methods(Field and Laboratory studies)",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person23,
      name: "Santosh kumar B",
      role: "Project Officer (Ph.D),Structural modeling, structural health assessment, NDT methods",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person24,
      name: "Elancheliyan D",
      role: "Project Officer (Ph.D),Watershed modeling, flood monitoring and modelling",
      department: "Department of Civil Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person25,
      name: "Rohini S",
      role: "Project Officer (Ph.D),Hydro & morpho-dynamic modeling,wave climate modelling in shallow water",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person26,
      name: "Benisha",
      role: "Project Officer (Ph.D),Coastal Vulnerability Mapping (Community based)",
      department: "Department of Humanities and Social Sciences",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person27,
      name: "HariRam N",
      role: "Project Associate (M.S),Wave vegetation interaction studies (lab scale and numerical), Eco system based adaptation",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person28,
      name: "Vasanthakumar S",
      role: "Project Officer (M.S),Beach profile monitoring and mapping​",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
    {
      imageUrl: person29,
      name: "Dhananjayan M",
      role: "Project​ Associate (M.S),Beach profile monitoring and mapping",
      department: "Department of Ocean Engineering",
      institution: "Indian Institute of Technology Madras",
    },
  ];
  const [currentCardIndex] = useState(0);

  const renderPeopleCards = (data) => {
    return data.map((person, index) => (
      <div
        key={index}
        className={`card-wrapper ${index === currentCardIndex ? "focus" : ""}`}
      >
        <div
          className={`window main-window ${
            index === currentCardIndex ? "focus" : ""
          }`}
          style={{ background: "#ffffff" }}
        >
          <div
            className="user-image"
            style={{ backgroundImage: `url(${person.imageUrl})` }}
          >
            <div>
              {person.link ? (
                <a
                  className="add-button"
                  href={person.link}
                  target="_blank"
                  rel="noopener"
                  style={{ color: "#ffffff" }}
                >
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                </a>
              ) : null}
            </div>
            <div className="username">{person.name}</div>
          </div>
          <div style={{ lineHeight: "1.0" }} className="user-info">
            <div className="quote" style={{fontSize:'20px',fontWeight:'bolder'}}>{person.role}</div>
            <div className="quote" >{person.department}</div>
            <div className="quote">{person.institution}</div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <h1
        className="header"
        style={{ textAlign: "center", fontFamily: "calibri"}}
      >
        Project Investigators
      </h1>
      <div className="container" id="container" style={{ display: "flex" }}>
        {renderPeopleCards(peopleData)}
      </div>
      <h1
        className="header"
        style={{ textAlign: "center", fontFamily: "calibri" }}
      >
        Co-Project Investigators
      </h1>
      <div className="container" id="container" style={{ display: "flex" }}>
        {renderPeopleCards(peopleData2)}
      </div>
      <h1
        className="header"
        style={{ textAlign: "center", fontFamily: "calibri" }}
      >
        Project staff
      </h1>
      <div className="container" id="container" style={{ display: "flex" }}>
        {renderPeopleCards(peopleData3)}
      </div>
    </>
  );
};

export default People;
