import React, { useEffect, useRef } from "react";
import Swiper from "swiper";
import "swiper/css";
import "../Styles/timeline.scss";
import "swiper/css/navigation";
import EventImg from "../Images/Inauguration/Main1-min.jpg";
import EventImg1 from "../Images/Inauguration/Main-min.jpg";
import EventImg2 from "../Images/Inauguration/Main3-min.jpg";
import EventImg3 from "../Images/Inauguration/Main4-min.jpg";
import EventImg4 from "../Images/Inauguration/main5-min.jpg";
import EventImg5 from "../Images/Inauguration/Main6-min.jpg";
import EventImg6 from "../Images/Inauguration/Main7-min.jpg";
//image 2
import img from "../Images/Inauguration/Beach_monitoring/gallery-min.jpg";
import img1 from "../Images/Inauguration/Beach_monitoring/gallery1-min.jpg";
import img2 from "../Images/Inauguration/Beach_monitoring/gallery2-min.jpg";
import img3 from "../Images/Inauguration/Beach_monitoring/gallery7-min.jpg";
import img4 from "../Images/Inauguration/Beach_monitoring/gallery4-min.jpg";
import img6 from "../Images/Inauguration/Beach_monitoring/gallery6-min.jpg";
//Structural health monitoring
import Img from "../Images/Inauguration/Structural_health_monitoring/SHI_Img-min.jpeg";
import Img1 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img1-min.jpeg";
import Img2 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img2-min.jpg";
import Img3 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img3-min.jpg";
import Img4 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img4-min.jpg";
import Img5 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img5-min.jpg";
import Img6 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img6-min.jpg";
import Img7 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img7-min.jpg";
import Img8 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img8-min.jpg";
import Img9 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img9-min.jpg";
import Img10 from "../Images/Inauguration/Structural_health_monitoring/SHI_Img10-min.jpg";
// Water resources management
import image from '../Images/Inauguration/Water_resources_management/WRM-min.jpg';
import image1 from '../Images/Inauguration/Water_resources_management/WRM1-min.jpg';
import image2 from '../Images/Inauguration/Water_resources_management/WRM2-min.jpg';
import image3 from '../Images/Inauguration/Water_resources_management/WRM3-min.jpg';
import image4 from '../Images/Inauguration/Water_resources_management/WRM4.png';
import image5 from  '../Images/Inauguration/Water_resources_management/WR6-min.jpg';
// Solid waste management
import Images from '../Images/Inauguration/Solid_waste_management/SWM.png';
import Images1 from '../Images/Inauguration/Solid_waste_management/SWM1-min.png';
import Images2 from '../Images/Inauguration/Solid_waste_management/SWM2-min.png';
import Images3 from '../Images/Inauguration/Solid_waste_management/SWM3.png';
// Community Awareness Program
import communityImg from '../Images/Inauguration/CommunityAwarenessProgram/CommunityAwareness1-min.jpg';
import communityImg1 from '../Images/Inauguration/CommunityAwarenessProgram/CommunityAwareness2-min.jpg';
import communityImg2 from '../Images/Inauguration/CommunityAwarenessProgram/CommunityAwareness3-min.jpg';
import communityImg3 from '../Images/Inauguration/CommunityAwarenessProgram/CommunityAwareness4-min.jpg';
import communityImg4 from '../Images/Inauguration/CommunityAwarenessProgram/community5-min.jpg';

const eventList = [
  {
    videoSource: "/videos/Demo.mp4",
  },
  {
    image: EventImg1,
  },
  {
    image: EventImg,
  },
  {
    image: EventImg2,
  },
  {
    image: EventImg3,
  },
  {
    image: EventImg4,
  },

  {
    image: EventImg5,
  },
  {
    image: EventImg6,
  },
];
const eventList2 = [
  {
    image: img,
  },
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },

  // {
  //   image: img5,
  // },
  {
    image: img6,
  },
];
const eventList3 = [
  {
    image: Img,
  },
  {
    videoSource: "/videos/Demo2.mp4",
  },
  {
    videoSource: "/videos/Demo1.mp4",
  },
  {
    image: Img2,
  },
  {
    image: Img1,
  },
  {
    image: Img3,
  },
  {
    image: Img4,
  },

  {
    image: Img5,
  },
  {
    image: Img6,
  },
  {
    image: Img7,
  },
  {
    image: Img8,
  },
  {
    image: Img9,
  },
  {
    image: Img10,
  },
];
const eventList4 = [
  {
    image: image,
  },
  {
    videoSource: "/videos/Demo3.mp4",
  },
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },

  {
    image: image5,
  },
 
];
const eventList5 = [
  {
    image: Images,
  },
  {
    image: Images1,
  },
  {
    image: Images2,
  },
  {
    image: Images3,
  },
  
];
const eventList6 = [
  {
    image: communityImg,
  },
  {
    image: communityImg1,
  },
  {
    image: communityImg2,
  },
  {
    image: communityImg3,
  },
  {
    image:communityImg4,
  },
  {
    videoSource: "/videos/Demo4.mp4",
  },
  
];
const Gallery = () => {
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const swiperRef3 = useRef(null);
  const swiperRef4 = useRef(null);
  const swiperRef5 = useRef(null);
  const swiperRef6 = useRef(null);
 
  useEffect(() => {
    const initSwiper = (selector, ref) => {
      const element = document.querySelector(selector);
      if (element) {
        ref.current = new Swiper(element, {
          direction: "vertical", // Customize as needed
          loop: true,
          speed: 1600,
          pagination: ".swiper-pagination",
          paginationClickable: true,
          breakpoints: {
            768: {
              direction: "horizontal",
            },
          },
          // ... Other Swiper configuration options
          on: {
            slideChange: function () {
              // Manually update background image for non-video slides
              const currentSlide = this.slides[this.activeIndex];
              const isVideoSlide = currentSlide.querySelector("video");
  
              if (!isVideoSlide) {
                const backgroundImage = currentSlide.style.backgroundImage;
                currentSlide.style.backgroundImage = ""; // Clear previous style
                currentSlide.style.backgroundImage = backgroundImage;
              }
            },
          },
        });
      }
    };
  
    initSwiper(".timeline .swiper-container", swiperRef);
    initSwiper(".timeline2 .swiper-container", swiperRef2);
    initSwiper(".timeline3 .swiper-container", swiperRef3);
    initSwiper(".timeline4 .swiper-container", swiperRef4);
    initSwiper(".timeline5 .swiper-container", swiperRef5);
    initSwiper(".timeline7 .swiper-container", swiperRef6);
  
    return () => {
      // Clean up Swiper instances when the component is unmounted
      const currentSwiperRef = swiperRef.current;
      const currentSwiperRef2 = swiperRef2.current;
      const currentSwiperRef3 = swiperRef3.current;
      const currentSwiperRef4 = swiperRef4.current;
      const currentSwiperRef5 = swiperRef5.current;
      const currentSwiperRef6 = swiperRef6.current;
  
      if (currentSwiperRef) {
        currentSwiperRef.destroy();
      }
      if (currentSwiperRef2) {
        currentSwiperRef2.destroy();
      }
      if (currentSwiperRef3) {
        currentSwiperRef3.destroy();
      }
      if (currentSwiperRef4) {
        currentSwiperRef4.destroy();
      }
      if (currentSwiperRef5) {
        currentSwiperRef5.destroy();
      }
      if (currentSwiperRef6) {
        currentSwiperRef6.destroy();
      }
    };
  }, [swiperRef]);
  
  return (
    <div
      className="container"
      style={{
        margin: "20px",
        width: "70%",
        justifyContent: "center",
        marginLeft: "244px",
      }}
    >
     
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
        }}
        className="header2"
      >
        Inauguration Of The Centre
      </h1>
      <div className="timeline">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {eventList.map((event, index) => (
              <div key={index} className="swiper-slide">
                {index === 0 ? (
                  <video controls width="100%" height="100%" autoPlay>
                    <source src={event.videoSource} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <div
                    key={index}
                    className="swiper-slide"
                    style={{
                      backgroundImage: `url(${event.image})`,
                      
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
          padding: "26px",
        }}
        className="header2" 
      >
       Community Awareness Program
      </h1>
      <div className="timeline7">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {eventList6.map((event, index) => (
              <div key={index} className="swiper-slide">
                {index === 5 ? (
                  <video controls width="100%" height="100%" >
                    <source src={event.videoSource} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <div
                    key={index}
                    className="swiper-slide"
                    style={{
                      backgroundImage: `url(${event.image})`,
                      
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef6.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef6.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
          padding: "26px",
        }}
        className="header2" 
      >
        Beach Monitoring
      </h1>
      <div className="timeline2">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {eventList2.map((event, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{
                  backgroundImage: `url(${event.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                data-year={event.year}
              ></div>
            ))}
          </div>
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef2.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef2.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
          padding: "26px",
        }}
        className="header2"
      >
        Structural Health Monitoring
      </h1>
      <div className="timeline3">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            
            {eventList3.map((event, index) => (
              <div key={index} className="swiper-slide">
                {index === 1 || index === 2 ? (
                  <video controls width="100%" height="100%">
                    <source src={event.videoSource} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <div
                    key={index}
                    className="swiper-slide"
                    style={{
                      backgroundImage: `url(${event.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef3.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef3.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
          padding: "26px",
        }}
        className="header2"
      >
        Water Resources Management
      </h1>
      <div className="timeline4">
        <div className="swiper-container">
          <div className="swiper-wrapper">
             {eventList4.map((event, index) => (
              <div key={index} className="swiper-slide">
                {index === 1 ? (
                  <video controls width="100%" height="100%">
                    <source src={event.videoSource} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <div
                    key={index}
                    className="swiper-slide"
                    style={{
                      backgroundImage: `url(${event.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef4.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef4.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
      <h1
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
          padding: "26px",
        }}
        className="header2"
      >
        Solid Waste Management
      </h1>
      <div className="timeline5">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {eventList5.map((event, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{ backgroundImage: `url(${event.image})` , backgroundSize: "cover",backgroundPosition: "center", }}
                data-year={event.year}
              ></div>
            ))}
          </div>
          <div
            className="swiper-button-prev"
            onClick={() => swiperRef5.current?.slidePrev()}
          />
          <div
            className="swiper-button-next"
            onClick={() => swiperRef5.current?.slideNext()}
          />
          <div className="swiper-pagination" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
