import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import DrawerComponent from "./Drawer";
import Icon1 from "../Images/Logo/IIT.png";
import Icon3 from "../Images/Logo/IGCS.png";
const useStyles = makeStyles((theme) => ({
  navlinks: {
    fontWeight: "bold",
    fontFamily: "calibri",
    alignItems: "center",
    padding: "10px",
    marginLeft: "-127px",
    display: "flex",
    textShadow: "2px 2px 6px #000000",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    fontFamily: "calibri",
    textDecoration: "none",
    color: "#ffffff",
    fontSize: "20px",
    marginLeft: theme.spacing(19),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "#000000",
      borderBottom: "1px solid white",
    },
  },
  activeLink: {
    fontFamily: "calibri",
    color: "#ffffff",
    borderBottom: "1px solid #000000",
  },
  appBar: {
    position: "fixed", // Position fixed to make it sticky
    top: 0, // Stick to the top
    zIndex: theme.zIndex.drawer + 1, // Ensure it's above the drawer if drawer exists
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* <div style={{marginLeft:"5px",marginRight:"5px" }}> */}
      <AppBar position="relative" className="colors" style={{ width: "100%" }}>
        <div>
          <br />
          <Typography
            style={{
              textAlign: "center",
              color: "#ffffff",
              textShadow: "2px 2px 7px #000000",
              fontSize: "33px",
              margin: "-15px",
              marginBottom:'-37px'
            }}
          >
            {/* color:"#30a1a7" */}
            DST-Centre of Excellence
          </Typography>
          <div style={{marginTop:'10px'}}>
          <a target="_blank"  rel="noreferrer" href="https://doe.iitm.ac.in/">
            <img
              src={Icon1}
              alt="IIT"
              style={{ width: "150px", marginLeft: " 110px" }}
            />
          </a>
          <a target="_blank"  rel="noreferrer"  href="https://www.igcs-chennai.org/">
            <img
              src={Icon3}
              alt="logo"
              style={{ width: "165px", marginLeft: "1387px" }}
            />
          </a>
          </div>
          <div style={{ marginTop: "-140px" }}>
            <Typography
              variant="h2"
              style={{
                // fontSize:'56px',
                // marginTop:'-110px;',
                textAlign: "center",
                color: "#ffffff",
                textShadow: "2px 2px 7px #000000",
              }}
            >
              Climate Change Impacts on Coastal Infrastructure
            </Typography>

            <Typography
              variant="h2"
              style={{
                textAlign: "center",
                color: "#ffffff",
                textShadow: "2px 2px 7px #000000",
              }}
            >
              And The Adaptation Strategies
            </Typography>
          </div>
          <br />
          <Typography
            style={{
              textAlign: "center",
              color: "#ffffff",
              textShadow: "2px 2px 7px #000000",
              fontSize: "33px",
            }}
          >
            Indian Institute of Technology Madras
          </Typography>

          <Divider />
        </div>
        <CssBaseline />
        <Toolbar style={{background:"rgb(10 117 188)"}}>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
              <NavLink
                to="/"
                className={classes.link}
                activeClassName={classes.activeLink}
                exact
              >
                Home
              </NavLink>
              <NavLink
                to="/Research"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Research
              </NavLink>
              <NavLink
                to="/People"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                People
              </NavLink>
              <NavLink
                to="/Publications"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Publications
              </NavLink>
              <NavLink
                to="/Events"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Events
              </NavLink>
              <NavLink
                to="/Gallery"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Gallery
              </NavLink>
              <NavLink
                to="/Presscoverage"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Presscoverage
              </NavLink>
              <NavLink
                to="/Contact"
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                Contact us
              </NavLink>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {/* </div> */}
    </>
  );
};
export default Navbar;
