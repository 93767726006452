import React, { useState } from "react";
import "../Styles/App.css";
import "font-awesome/css/font-awesome.min.css";
function Journalpapers() {
  const data = [
    {
      title:
        "A sensitivity study of WRF model microphysics and cumulus parameterization schemes for the simulation of tropical cyclones using GPM radar data",
      content: [
        "A sensitivity study of WRF model microphysics and cumulus parameterization schemes for the simulation of tropical cyclones using GPM radar data. J Earth Syst Sci 130, 190 (2021).",
        ".Baki, H., Chinta, S., Balaji, C. et al.",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s12040-021-01682-3"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Parameter Calibration to Improve the Prediction of Tropical Cyclones over the Bay of Bengal Using Machine Learning–Based Multiobjective Optimization",
      content: [
        "Parameter Calibration to Improve the Prediction of Tropical Cyclones over the Bay of Bengal using Machine Learning-based Multi-Objective Optimization. Journal of Applied Meteorology and Climatology.",
        ".Baki, H., Chinta, S., Balaji, C., & Srinivasan, B. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1175/JAMC-D-21-0184.1"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title: "Assessment of coastal vulnerability for extreme events",
      content: [
        "Assessment of coastal vulnerability for extreme events. International Journal of Disaster Risk Reduction, 103341.",
        ". Ahmed, M. A., Sridharan, B., Saha, N., Sannasiraj, S. A., & Kuiry, S. N. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.apor.2020.102116"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Climate change projection of wave climate due to Vardah cyclone in the Bay of Bengal",
      content: [
        "Climate change projection of wave climate due to Vardah cyclone in the Bay of Bengal. Dynamics of Atmospheres and Oceans, 97, 101279",
        ".Bhavithra, R. S., & Sannasiraj, S. A. (2022)",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1016/j.dynatmoce.2021.101279"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Koppen–Trewartha climate classification as a diagnostic tool to identify pronounced changes in the projected climate by the General Circulation Models over India",
      content: [
        "Koppen–Trewartha climate classification as a diagnostic tool to identify pronounced changes in the projected climate by the General Circulation Model s over India. International Journal of Climatology, 41(15), 6616-6639",
        ".Bindhu, V. M., Smitha, P. S., Narasimhan, B., Annamalai, H., & Srinivasan, G. (2021)",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1002/joc.7216"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Removal of antibiotics from aqueous solutions by electrocatalytic degradation",
      content: [
        "Removal of antibiotics from aqueous solutions by electrocatalytic degradation. Environmental Science: Nano, 8(5), 1133-1176",
        ".Choudhary, V., Vellingiri, K., Thayyil, M. I., & Philip, L. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1039/D0EN01276A"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Historical Shoreline Analysis and Field Monitoring at Ennore Coastal Stretch along the Southeast Coast of India",
      content: [
        "Historical Shoreline Analysis and Field Monitoring at Ennore Coastal Stretch along the Southeast Coast of India, Marine Geodesy, 45:1, 47-74",
        ".Dhananjayan M., Vasanthakumar S., Sannasiraj S. A. & Murali K. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1080/01490419.2021.1992546"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Wave-vegetation interaction using Improved Meshless Local Petrov Galerkin method",
      content: [
        "Wave-vegetation interaction using Improved Meshless Local Petrov Galerkin method. Applied Ocean Research, 101, 102116",
        ".Divya, R., Sriram, V., & Murali, K. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.apor.2020.102116"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Characterization of segregated greywater from Indian households: part A—physico-chemical and microbial parameters",
      content: [
        "Characterization of segregated greywater from Indian households: part A—physico-chemical and microbial parameters. Environmental Monitoring and Assessment, 192(7), 1-23.",
        ".Delhiraja, K., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s10661-020-08369-0"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Characterization of segregated greywater from Indian households—part B: emerging contaminants",
      content: [
        "Characterization of segregated greywater from Indian households—part B: emerging contaminants. Environmental Monitoring and Assessment, 192(7), 1-19.",
        ".Delhiraja, K., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s10661-020-08370-7"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title: "Impact of urban sprawl on future flooding in Chennai city, India",
      content: [
        "Impact of urban sprawl on future flooding in Chennai city, India. Journal of Hydrology, 574, 486-496.",
        ".Devi, N. N., Sridharan, B., & Kuiry, S. N. (2019).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.jhydrol.2019.04.041"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Analysis of shoreline change between inlets along the coast of Chennai, India",
      content: [
        "Analysis of shoreline change between inlets along the coast of Chennai, India. Marine Georesources & Geotechnology, 40(1), 26-35.",
        ".Gracy Margret Mary, R., Sundar, V., & Sannasiraj, S. A. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1080/1064119X.2020.1856241"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Complex Interplay Between Organic and Secondary Inorganic Aerosols With Ambient Relative Humidity Implicates the Aerosol Liquid Water Content Over India During Wintertime",
      content: [
        "Complex interplay between organic and secondary inorganic aerosols with ambient relative humidity implicates the aerosol liquid water content over India during wintertime. Journal of Geophysical Research: Atmospheres, p.e2021JD036430.",
        ".Gopinath, A.K., Raj, S.S., Kommula, S.M., Jose, C., Panda, U., Bishambu, Y., Ojha, N., Ravikrishna, R., Liu, P. and Gunthe, S.S., 2022.",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1029/2021JD036430"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Enhanced aerosol particle growth sustained by high continental chlorine emission in India",
      content: [
        "Enhanced aerosol particle growth sustained by high continental chlorine emission in India. Nature Geoscience, 14(2), 77-84.",
        ".Gunthe, S. S., Liu, P., Panda, U., Raj, S. S., Sharma, A., Darbyshire, E.,& Coe, H. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1038/s41561-020-00677-x"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Flow-structure interference effects with the surrounding structure in the choked quasi-steady condition of tsunami: Comparison with traditional obstruction approach",
      content: [
        "Flow-structure interference effects with the surrounding structure in the choked quasi-steady condition of tsunami: Comparison with traditional obstruction approach. Applied Ocean Research, 126, 103255.",
        ".Harish, S., Sriram, V., Schüttrumpf, H., & Sannasiraj, S. A. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.apor.2022.103255"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Tsunami-like flow induced forces on the structure: Dependence of the hydrodynamic force coefficients on Froude number and flow channel width in quasi-steady flow phase",
      content: [
        "Tsunami-like flow induced forces on the structure: Dependence of the hydrodynamic force coefficients on Froude number and flow channel width in quasi-steady flow phase. Coastal Engineering, 172, 104078.",
        ".Harish, S., Sriram, V., Schüttrumpf, H., & Sannasiraj, S. A. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.coastaleng.2021.104078"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Tsunami-like flow induced force on the structure: Prediction formulae for the horizontal force in quasi-steady flow phase",
      content: [
        "Tsunami-like flow induced force on the structure: Prediction formulae for the horizontal force in quasi-steady flow phase. Coastal Engineering, 103938.",
        ".Harish, S., Sriram, V., Schüttrumpf, H., & Sannasiraj, S. A. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.coastaleng.2021.103938"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Experimental investigation on the characteristics of solitary and elongated solitary waves passing over vegetation belt",
      content: [
        "Experimental investigation on the characteristics of solitary and elongated solitary waves passing over vegetation belt. J. Ocean Eng. Mar. Energy (2022).",
        ".Hari Ram, N., Sriram, V. & Murali, K.",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s40722-022-00233-2"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Very severe cyclonic storm impacts to shoreline and beach profiles along the Karaikal coast of India",
      content: [
        "Very severe cyclonic storm impacts to shoreline and beach profiles along the Karaikal coast of India. ISH Journal of Hydraulic Engineering, 28(sup1), 439-448.",
        ".Jeyagopal, S., Singaravelu, V., Dhananjayan, M., Sundar, V., Sannasiraj, S. A., & Murali, K. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1080/09715010.2020.1767515"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Comparative study of degradation of toluene and methyl isobutyl ketone (MIBK) in aqueous solution by pulsed corona discharge plasma",
      content: [
        "Comparative study of degradation of toluene and methyl isobutyl ketone (MIBK) in aqueous solution by pulsed corona discharge plasma. Journal of Environmental Sciences, 101, 382-396.",
        ".Jose, J., & Philip, L. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.jes.2020.09.003"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Continuous flow pulsed power plasma reactor for the treatment of aqueous solution containing volatile organic compounds and real pharmaceutical wastewater",
      content: [
        "Continuous flow pulsed power plasma reactor for the treatment of aqueous solution containing volatile organic compounds and real pharmaceutical wastewater. Journal of Environmental Management, 286, 112202.",
        ".Jose, J., & Philip, L. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1016/j.jenvman.2021.112202"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Effect of various electrolytes and other wastewater constituents on the degradation of volatile organic compounds in aqueous solution by pulsed power plasma technology",
      content: [
        "Effect of various electrolytes and other wastewater constituents on the degradation of volatile organic compounds in aqueous solution by pulsed power plasma technology. Environmental Science: Water Research & Technology, 6(8), 2209-2222.",
        ".Jose, J., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1039/D0EW00388C"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Impact of climate change on intense Bay of Bengal tropical cyclones of the post-monsoon season: a pseudo global warming approach",
      content: [
        "Impact of climate change on intense Bay of Bengal tropical cyclones of the post-monsoon season: a pseudo global warming approach. Climate Dynamics, 56(9), 2855-2879.",
        ".Jyoteeshkumar Reddy, P., Sriram, D., Gunthe, S. S., & Balaji, C. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1007/s00382-020-05618-3"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Chennai extreme rainfall event of 2015 under future climate projections using the pseudo global warming dynamic downscaling method",
      content: [
        "Chennai extreme rainfall event of 2015 under future climate projections using the pseudo global warming dynamic downscaling method. Current Science (00113891), 118(12).",
        ".Jyoteeshkumar, P., Kiran, P. V., & Balaji, C. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.18520/cs/v118/i12/1968-1979"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Risk communication and capacity-building: A case study on framing CBA strategies of artisanal fishing communities to sea-level rise using BASIEC",
      content: [
        "Risk communication and capacity-building: A case study on framing CBA strategies of artisanal fishing communities to sea-level rise using BASIEC. Climate Services, 26, 100299.",
        ".Khan, A. S., Kumar, M. S., & Chella, R. S. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.cliser.2022.100299"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Chemical Characterization and Source Apportionment of Organic Aerosols in the Coastal City of Chennai, India: Impact of Marine Air Masses on Aerosol Chemical Composition and Potential for Secondary Organic Aerosol Formation",
      content: [
        "Chemical Characterization and Source Apportionment of Organic Aerosols in the Coastal City of Chennai, India: Impact of Marine Air Masses on Aerosol Chemical Composition and Potential for Secondary Organic Aerosol Formation. ACS Earth and Space Chemistry, 5(11), 3197-3209.",
        ".Kommula, S. M., Upasana, P., Sharma, A., Raj, S. S., Reyes-villegas, E., Liu, T., ... & Gunthe, S. S. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1021/acsearthspacechem.1c00276"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Electrochemical process employing scrap metal waste as electrodes for dye removal",
      content: [
        "Electrochemical process employing scrap metal waste as electrodes for dye removal. Journal of Environmental Management, 273, 111039.",
        ".Nippatla, N., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.jenvman.2020.111039"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Investigation of Role of Retention Storage in Tanks (Small Water Bodies) on Future Urban Flooding: A Case Study of Chennai City, India",
      content: [
        "Investigation of Role of Retention Storage in Tanks (Small Water Bodies) on Future Urban Flooding: A Case Study of Chennai City, India. Water, 12(10), 2875.",
        ".Nithila Devi, N., Sridharan, B., Bindhu, V. M., Narasimhan, B., Bhallamudi, S. M., Bhatt, C. M., ... & Kuiry, S. N. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.3390/w12102875"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title: "A comprehensive review on structural tsunami countermeasures",
      content: [
        "A comprehensive review on structural tsunami countermeasures. Nat Hazards (2022).",
        ".Oetjen, J., Sundar, V., Venkatachalam, S. et al.",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s11069-022-05367-y"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "On the widespread enhancement in fine particulate matter across the Indo-Gangetic Plain towards winter",
      content: [
        "On the widespread enhancement in fine particulate matter across the Indo-Gangetic Plain towards winter. Scientific reports, 10(1), 1-9.",
        ".Ojha, N., Sharma, A., Kumar, M., Girach, I., Ansari, T. U., Sharma, S. K., ... & Gunthe, S. S. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1038/s41598-020-62710-8"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "The future projection of cyclones in Bay of Bengal: a study using coupled ocean atmosphere modelOn the widespread enhancement in fine particulate matter across the Indo-Gangetic Plain towards winter",
      content: [
        "The future projection of Cyclones in Bay of Bengal: a study using coupled ocean atmospheric model, accepted for publication in Ocean Dynamics, Springer.",
        ".P.V. Kiran and Balaji, C. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s10236-022-01522-y"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Numerical investigations for mitigation of tsunami wave impact on onshore buildings using sea dikes",
      content: [
        "Numerical investigations for mitigation of tsunami wave impact on onshore buildings using sea dikes. Ocean Engineering, 187, 106159.",
        ".Prabu, P., Bhallamudi, S. M., Chaudhuri, A., & Sannasiraj, S. A. (2019).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1016/j.oceaneng.2019.106159"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Freefall hydrodynamics of torpedo anchors through experimental and numerical analysis",
      content: [
        " Freefall hydrodynamics of torpedo anchors through experimental and numerical analysis. Ocean Engineering, 243, 110213..",
        ".Raaj, S. K., Saha, N., & Sundaravadivelu, R. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.oceaneng.2021.110213"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Investigation of morphodynamic evolution in a shelf region of Bay of Bengal under extreme conditions",
      content: [
        "Investigation of morphodynamic evolution in a shelf region of Bay of Bengal under extreme conditions. Natural Hazards, 1-20",
        ".Rohini, S., Sannasiraj, S. A., & Sundar, V. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s11069-022-05797-8"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Investigation on influence of RCP-based climate scenarios on the hydrodynamic and morphodynamic changes during extreme events",
      content: [
        "Investigation on influence of RCP-based climate scenarios on the hydrodynamic and morphodynamic changes during extreme events. Applied Ocean Research",
        ". Rohini, S., Sannasiraj, S.A. and Sundar, V. (2023).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.apor.2023.103660"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Hydrodynamic Modelling of Storm Surge with Modified Wind Fields along the East Coast of India",
      content: [
        "Hydrodynamic Modelling of Storm Surge with Modified Wind Fields along the East Coast of India. MARINE GEODESY",
        ". Selvaraj, R., Sannasiraj, S. A., & Vallam, S. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1080/01490419.2022.2082603"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Effect of Climate Change in the Deterioration of a Berthing Structure in a Tropical Environment",
      content: [
        " Effect of Climate Change in the Deterioration of a Berthing Structure in a Tropical Environment. Journal of The Institution of Engineers (India): Series A, 102(3), 697-703",
        ". Santosh Kumar, B., Sannasiraj, S. A., & Murali, K. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s40030-021-00532-1"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Effects of Dry Deposition on Surface Ozone over South Asia Inferred from a Regional Chemical Transport Model",
      content: [
        "Effects of the dry deposition on surface ozone over South Asia inferred from a regional chemical transport model”, ACS Earth and Space Chemistry",
        ". Sharma, Amit; Ojha, Narendra; Ansari, Tabish; Sharma, Som; Pozzer, Andrea; Gunthe, Sachin (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1021/acsearthspacechem.0c00004"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Improvements in wind field hindcast for storm surge predictions in the Bay of Bengal: A case study for the tropical cyclone Varadah",
      content: [
        "Improvements in wind field hindcast for storm surge predictions in the Bay of Bengal: A case study for the tropical cyclone Varadah. Applied Ocean Research, 127, 103324",
        ". Shashank, V. G., Sriram, V., & Sannasiraj, S. A. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.apor.2022.103324"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Improved accuracy of storm surge simulations by incorporating changing along-track parameters",
      content: [
        " Improved Accuracy of Storm Surge Simulations by Incorporating Changing Along-track Parameters. International journal of climatology",
        ". Sridharan, B., Krishna Chaitanya, Sudheer, K. P., and Soumendra Nath Kuiry (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1002/joc.7620"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Longshore sediment transport rate from the field measured wave and sediment characteristics along the coast of Karaikal, India",
      content: [
        " Longshore sediment transport rate from field measured wave and sediment characteristics along the coast of Karaikal, India; ISH Journal of Hydraulic Engineering",
        ". Sundar V, Sannasiraj, S. A. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1080/09715010.2022.2086833"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Shoreline changes due to construction of groyne field in north of Chennai Port, India",
      content: [
        " Shoreline changes due to construction of groyne field in north of Chennai Port, India. Environmental monitoring and assessment, 193(12), 1-15",
        ". Sundar, V., Sannasiraj, S. A., Ramesh Babu, S., & Rajakan, G. M. M. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s10661-021-09590-1"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Impact of coastal structure on shorelines along the southeast and southwest coasts of india",
      content: [
        " Impact of coastal structure on shorelines along the southeast and southwest coasts of india. ISH Journal of Hydraulic Engineering, 1-19",
        ". Sundar, V., Sannasiraj, S. A., Murali, K., & Singaravelu, V. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1080/09715010.2022.2115319"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Fate and impact of pharmaceuticals and personal care products during septage co-composting using an in-vessel composter",
      content: [
        " Fate and impact of pharmaceuticals and personal care products during septage co-composting using an in-vessel composter. Waste Management, 109, 109-118",
        ". Thomas, A. R., Kranert, M., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1016/j.wasman.2020.04.053"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Investigation on greenhouse gas emissions and compost dynamics during in-vessel co-composting of septage and mixed organic wastes",
      content: [
        " Investigation on greenhouse gas emissions and compost dynamics during in-vessel co-composting of septage and mixed organic wastes. International Journal of Environmental Science and Technology, 17(3), 1675-1690",
        ". Thomas, A. R., Arulraj, P. R., Kranert, M., & Philip, L. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s13762-019-02450-z"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "In-vessel co-composting-a rapid resource recovery option for septage treatment in Indian cities",
      content: [
        " In-vessel co-composting–a rapid resource recovery option for septage treatment in Indian cities. Journal of Water, Sanitation and Hygiene for Development, 8(4), 688-697",
        ". Thomas, A. R., Kranert, M., & Philip, L. (2018).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.2166/washdev.2018.046"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Pitfalls in hydrologic model calibration in a data scarce environment with a strong seasonality: experience from the Adyar catchment, India",
      content: [
        " Pitfalls in hydrologic model calibration in a data scarce environment with a strong seasonality: experience from the Adyar catchment, India. Environ Earth Sci 82, 367 (2023)",
        ". Tigabu, T.B., Wagner, P.D., Narasimhan, B. et al.",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s12665-023-11047-2"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Swell and sea characteristics along the south-west coast of India during the monsoon",
      content: [
        "Swell and sea characteristics along the south-west coast of India during the monsoon. Ocean Engineering, 237, 109572",
        ". Uma, G., & Sannasiraj, S. A. (2021).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1016/j.oceaneng.2021.109572"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Investigation of stochastic nonlinear dynamics of ocean engineering systems through path integration",
      content: [
        "Investigation of stochastic nonlinear dynamics of ocean engineering systems through path integration. Physica D: Nonlinear Phenomena, 402, 132227",
        ". Varghese, V. A., & Saha, N. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1016/j.physd.2019.132227"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Wave forces on a concave front pile-supported breakwater combined with wave screen under regular waves",
      content: [
        "Wave forces on a concave front pile-supported breakwater combined with wave screen under regular waves. Journal of Ocean Engineering and Marine Energy, 1-21",
        ". Vasanthakumar, S., Karthik Ramnarayan, S., Sannasiraj, S. A., & Sundar, V. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1007/s40722-022-00270-x"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Wave forces and moments on concave and vertical front caissons supported on piles",
      content: [
        " Wave forces on a concave front pile-supported breakwater combined with wave screen under regular waves. Journal of Ocean Engineering and Marine Energy, 9(2), 319-339",
        ". Vasanthakumar, S., Karthik Ramnarayan, S., Sannasiraj, S. A., & Sundar, V. (2023).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1080/17445302.2023.2233852"
            target="_blank"
            rel="noreferrer"
            
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Bioaerosol impact on crop health over India due to emerging fungal diseases (EFDs): an important missing link",
      content: [
        " Bioaerosol impact on crop health over India due to emerging fungal diseases (EFDs): an important missing link. Environmental Science and Pollution Research, 27(11), 12802-12829",
        ". Yadav, S., Gettu, N., Swain, B., Kumari, K., Ojha, N., & Gunthe, S. S. (2020).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.1007/s11356-020-08059-x"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Performance Assessment of High-Resolution Remote Sensing Rainfall Products and their utility in simulating Extreme Hydrological Events in a Peri-urban Catchment",
      content: [
        " Performance Assessment of High-Resolution Remote Sensing Rainfall Products and their utility in simulating Extreme Hydrological Events in a Peri-urban Catchment",
        ". Yaswanth, P., Bindhu, V. M., Kannan, B. S. A. M., Balaji, C., & Narasimhan, B. (2022).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href=" https://doi.org/10.21203/rs.3.rs-2304860/v1"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    {
      title:
        "Evaluation of Remote Sensing Rainfall Products, Bias Correction and Temporal Disaggregation Approaches, for Improved Accuracy in Hydrologic Simulations",
      content: [
        " Evaluation of Remote Sensing Rainfall Products, Bias Correction and Temporal Disaggregation Approaches, for Improved Accuracy in Hydrologic Simulations. Water Resources Management, 1-24",
        ". Yaswanth, P., Kannan, B. A. M., Bindhu, V. M., Balaji, C., & Narasimhan, B. (2023).",
        <p>
          {" "}
          For more Information of this Publications Click the link below{" "}
          <a
            style={{ color: "blue" }}
            key="link"
            href="https://doi.org/10.1007/s11269-023-03486-0"
            target="_blank"
            rel="noreferrer"
          >
            <p style={{ color: "#ffffff" }}>https://doi.org</p>
          </a>{" "}
        </p>,
      ],
    },
    
  ];


  const [accordionItems, setAccordionItems] = useState(
    data.map((item) => ({ ...item, open: false }))
  );

  const toggleAccordion = (index) => {
    const updatedAccordion = [...accordionItems];
    updatedAccordion[index].open = !updatedAccordion[index].open;
    setAccordionItems(updatedAccordion);
  };

  return (
    <div>
      {/* <AppBar position="static">
      </AppBar> */}
      {/* <Navbar style={{margin:"10px"}}/> */}
      <div className="GeeksForGeeks">
        <h1 style={{ textAlign: "center", padding: "95px", color: "#ffffff" }}>
        Journalpapers
        </h1>
        <div
          c
          lassName="accordion"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "-78px",
          }}
        >
          {accordionItems.map((item, index) => (
            <div key={index} style={{ width: `calc(50% - 10px)` }}>
              <div
                className={`title ${item.open ? "active" : ""}`}
                onClick={() => toggleAccordion(index)}
              >
                <i
                  className={`fa fa-angle-down ${
                    item.open ? "fa-rotate-180" : ""
                  }`}
                ></i>
                <span className="title-text">{item.title}</span>
              </div>
              <div className={`content ${item.open ? "content-open" : ""}`}>
                <div
                  className={`content-text ${
                    item.open ? "content-text-open" : ""
                  }`}
                >
                  {item.content}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Journalpapers;
