import React, { useEffect, useRef } from "react";
import Swiper from "swiper";
import "swiper/css";
import "../Styles/timeline.scss";
import "swiper/css/navigation";
import EventImg from "../Images/events/CommunityAwareness2-min.jpg";
import EventImg1 from "../Images/events/Nature-Based.jpg";
import EventImg2 from "../Images/events/23rdcongress.jpg";
import EventImg3 from "../Images/events/WaterSecurity.jpg";
import EventImg4 from "../Images/events/Event3.jpg";
import EventImg5 from "../Images/events/Event6.jpg";
import EventImg6 from "../Images/events/ClimateChangeImpacts.jpg";
import EventImg7 from "../Images/events/Event2.jpg";
import EventImg8 from "../Images/events/Event4.jpg";
import EventImg9 from "../Images/events/Event5.jpg";
const eventList = [
  {
    year: '2023',
    image: EventImg,
    title: "Community Awareness Program",
    description:
      "Community awareness program regarding cyclone impacts in the Koraikuppam coastal village near Palaverkadu in Thiruvallur district, following the recent impact of Severe Cyclonic Storm Michaung. The program is jointly organized by DST-CoE (Climate Change Impacts on Coastal Infrastructure and Adaptation Strategies) and the Global Water and Climate Adaptation Centre (ABCD Centre) on December 28, 2023. ",
  },
  {
    year: '2022',
    image: EventImg4,
    title: "Exhibits Presention",
    description:
      "Exhibits presented on OCEANS 2022 Conference and Exposition Feb 21-24, 2022",
  },
  {
    year: '2022',
    image: EventImg2,
    title: "Exhibits Presention",
    description:
      " Exhibits presented on “23rd congress of Congress of the International Association for Hydro-Environment Engineering and Research - Asia Pacific Division on the topic of Climate change from 14 to 17 December 2022.",
  },
  {
    year: "2022",
    image: EventImg1,
    title: "A Workshop On",
    description:
      'Workshop on "Nature-Based Water Management Techniques in Coastal Aquifers" on February 24 and 25 at IIT Madras.',
  },
  {
    year: "2021",
    image: EventImg6,
    title: "A Special Session On",
    description:
      "“Climate Change Impacts on Water and Coastal Infrastructures” has been organised by Prof. S.A. Sannasiraj IIT Madras in HYDRO 2021 - International conference organized by NIT Surat",
  },
  {
    year: "2021",
    image: EventImg3,
    title: "A Special Session On",
    description:
      "Relevance of coastal zones in the context of water security and climate adaptation” has been conducted by Prof. Sannasiraj in Evidence for Action Symposium on 21 July 2021.",
  },
  {
    year: "2019",
    image: EventImg5,
    title: "A Special Session On",
    description:
      "“Climate Change Impacts on Coasts” has been conducted by Prof. S.A. Sannasiraj & Prof. B.S. Murty, IIT Madras in HYDRO 2019 - International conference held in Osmania University on 19.12.2019 AN.",
  },
  // Add more events as needed
  {
     year: '2019',
    image: EventImg7,
    title: "A Training Program",
    description:
      "A training program on “Design of Coastal Structures including Extreme Events” has been conducted by Prof. V. Sundar and Dr. V. Sriram between 16th – 22nd, October, 2019 for Global Initiative of Academic Networks",
  },

  {
    // year: '2019',
    image: EventImg8,
    title: "A Workshop On",
    description:
      "“SAWT Climate Change Studies” has been conducted by Prof. Sudheer, Prof. Balaji Narasimhan and Prof. Indumathi Nambi.",
  },
  {
    year: '2015',
    image: EventImg9,
    title: "A Workshop On",
    description:
      "“Flood Early Warning System for Chennai: Lessons from 2015 Floods”, Real Time Flood Forecasting and Spatial Decision Support Decision Support System (RTFF & SDSS) for Chennai Basin Workshop –I, Chennai, organized by Prof. Balaji Narasimhan ",
  },
  
];

const TimelineSlider = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current = new Swiper(".timeline6 .swiper-container", {
      direction: "vertical",
      loop: true,
      speed: 1600,
      pagination: ".swiper-pagination",
      paginationClickable: true,
      breakpoints: {
        768: {
          direction: "horizontal",
        },
      },
    });

    console.log("Swiper initialized");

    return () => {
      // Clean up Swiper instance when the component is unmounted
      swiperRef.current.destroy();
    };
  }, []);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div
      className="container"
      style={{
        margin: "20px",
        width: "70%",
        justifyContent: "center",
        marginLeft: "244px",
      }}
    >
      <h1
        className="header"
        style={{
          fontFamily: "calibri",
          fontSize: "34px",
          letterSpacing: "15px",
          color: "#fff",
        }}
      >
        Completed Events
      </h1>
      <div className="timeline6">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {eventList.map((event, index) => (
              <div
                key={index}
                className="swiper-slide"
                style={{ backgroundImage: `url(${event.image})` }}
                data-year={event.year}
              >
                <div className="swiper-slide-content">
                  <span className="timeline-year">{event.year}</span>
                  <h4 className="timeline-title">{event.title}</h4>
                  <p className="timeline-text">{event.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-button-prev" onClick={handlePrevSlide} />
          <div className="swiper-button-next" onClick={handleNextSlide} />
          <div className="swiper-pagination" />
        </div>
      </div>
    </div>
  );
};

export default TimelineSlider;
