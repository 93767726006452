import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import "../Styles/parllex.css";
import "../Styles/Animation.css";
import Images from "../Images/Research/Intro-min.jpg";
import Images1 from "../Images/Research/Climate-min.jpg";
import Images2 from "../Images/Research/waste-min.jpg";
import Images3 from "../Images/Research/Hydro-min.jpg";
import Images4 from "../Images/Research/inunation-min.jpg";
import Images5 from "../Images/Research/survey-min.jpg";
import Images6 from "../Images/Research/water_resource-min.jpg";
import Images7 from "../Images/Research/waste-min.jpg";
import Images8 from "../Images/Research/EcoSystem-min.jpg";
import Images9 from "../Images/Research/Adaption-min.jpg";
import Images10 from "../Images/Research/Risk-min.jpg";

const cardData = [
  {
    "title": "Climate Modelling",
    "image": Images1,
    "content": "Tropical cyclones and extreme weather events are the most common natural disasters which cause severe damage to coastal infrastructures. The changes in the intensity and frequency of these events in future climatic conditions should be studied in order to reduce the destruction in future. Atmospheric Modeling is focused on answering these queries scientifically.",
  },
  // Add more cards as needed
  {
    "title": "Wave Climate Modelling",
    "image": Images2,
    "content": "The cyclonic landfall makes a substantial impact on coastal regions. Hence, it is significant to study the effect of severe storms on coastal morphology. The research is based on a prediction of coastal morphological changes due to extreme events. The study focuses on the interactions between waves and tidal currents and their effects on morphodynamics. In an effort to gain a better understanding of Global Warming Revisited, a new technique was used to investigate the organization and functionality of the diverse parts of my experimental model. The work will be expanded by collaborating with other labs that have the facilities and prior experience to investigate this project further.",
    "links": [
      {
        "text": "Shorts notes on wave Climate modeling",
        "url": "https://drive.google.com/file/d/1hlUoaynI9HZz3yngYyNsIBvBwtAXvyKr/view"
      },
      {
        "text": "Wave Climate Simulation using WAM Model",
        "url": "https://drive.google.com/file/d/1wXKjzzXaU-86la7LB7iBqxMVG1V7nug0/view"
      }
    ]
  },
  {
    "title": "Hydro & Morphodynamic Modelling",
    "image": Images3,
    "content": "The cyclonic landfall makes a substantial impact on coastal regions. Hence, it is significant to study the effect of severe storms on coastal morphology. The research is based on a prediction of coastal morphological changes due to extreme events. The study focuses on the interactions between waves and tidal currents and their effects on morphodynamics. In an effort to gain a better understanding of Global Warming Revisited, a new technique was used to investigate the organization and functionality of the diverse parts of my experimental model. The work will be expanded by collaborating with other labs that have the facilities and prior experience to investigate this project further.",
  },
  {
    "title": "Inundation Mapping",
    "image": Images4,
    "content": "A flood inundation map library is a set of maps that shows where flooding may occur over a range of water levels in the community's local stream, river and coastal areas. Using various numerical modelling tools the flood inundation maps will be generated for RCP scenarios. These maps will be helpful to identify the vulnerable locations to take necessary adaptation measures.",
    
  },
  {
    "title": "Beach Monitoring",
    "image": Images5,
    "content": "Beach monitoring focuses on the determination of shoreline changes due to climate change, extreme weather events and also its behaviour near the coastal structures. Nearshore beach processes are also monitored using field surveys using the Real-Time Kinematic Global Positioning System (RTK-GPS) having a positional accuracy range of 8mm to 15mm to collect data. Estimation changes on a seasonal scale as well as an annual scale. In addition, we collect field data on Pre- and Post- extreme weather events. This data is useful for benchmarking the numerical models. Satellite photogrammetry is carried out in pre-defined locations on a historical scale to establish the trends in shoreline change.",
    
  },
  {
    "title": "Water  Resource Management",
    "image": Images6,
    "content": "Water Resource Management focuses on the impact of climate change on the surface water component of coastal cities. Based on the core theme of climate-resilient cities, using a network of sensors & real-time field experiments for data collection in addition to the combination of climate, hydrologic and water use evaluation models, we assess the resilience of existing water resources and propose ways to improve it. Our research group's results help to support the UN Sustainable Development Goals for providing clean water to cities in the climate-changing scenario in a sustainable way.",
    "links": [
      {
        "text": "koppen-Trewartha Climate Classification for India",
        "url": "https://drive.google.com/file/d/1OU-rDE_XbJ70C6SXuffM0LqSKYpVZx4_/view"
      },
      
    ]
  },
  {
    "title": "Waste Management",
    "image": Images7,
    "content": "Climate change causes variations in the quality and quantity of wastewater, making it difficult to be treated by conventional methods. Climate change-induced water scarcity makes it imperative to consider wastewater as a potential source of water after the necessary treatment. Our work proposes a scheme for in-stream treatment of wastewater by combining biofilm treatment, hydroponic treatment and electrocatalysis, thus providing a comprehensive method to reduce the dependency on centralised treatment plants. This will also promote wastewater reuse at a local level by using technologies that are resilient to the impacts of climate change.",
    "links": [
      {
        "text": " Short notes on Adaptation of Waste Management Infrastructure in Coastal Areas to Climate Change",
        "url": "https://drive.google.com/file/d/1TUp3W2oizaXDyM2vgvPrwqUa_i4rEBRT/view"
      },
      {
        "text": " Short Video on Inttegrated Solid Waste Management",
        "url": "https://drive.google.com/file/d/18oxWj5uagPNbN1qCtD8b0Ob4Y4_furBp/view"
      },
    ]
  },
  {
    "title": "Ecosystem Based Adaptation",
    "image": Images8,
    "content": "The great Indian Ocean Tsunami (2004) had significantly impacted the coastal front of many countries. Protecting coastal regions from such events in the future scenario has been foreseen, and eco-friendly (vegetation patches) protection measures are being studied. Understanding the interaction of extreme wave events with vegetal patches and quantifying the level of protection offered by different vegetal patches are being investigated through experimental and numerical modelling. This understanding will lead us to propose guidelines for planning the vegetation patches along the coastlines based on the morphological and hydrodynamic features of the sites by considering future climate changes.",
    "links": [
      {
        "text": "Eco-system based solution as a coastal defense structure for coastal smart cities",
        "url": "https://drive.google.com/file/d/1VWDAYDuQSBSKR6uA2K-U47uPkNpBXZpF/view"
      },
      {
        "text": "Wave Vegetation Interaction Using IMPLG_R method",
        "url": "https://drive.google.com/file/d/1iDaHKiyqOaPri79aMqZ1iiLzpqJQDN-8/view"
      },
      {
        "text": "Wave Vegetation Interaction Using IITM-RANS3D",
        "url": "https://drive.google.com/file/d/1Ese0bsE_ogc4RBW7E4Yd5sdXrRLjnECQ/view"
      },
    ]
  },
  {
    "title": "Adaptation measures for Coastal Infrastructure",
    "image": Images9,
    "content": "The infrastructure along the coastal lines like ports, harbours, roads, power plants, desalination plants etc. are very much crucial for the nation's economy as well as for the well being of the coastal community. These coastal infrastructures are more vulnerable due to the dynamic nature of the coastline and its environment. Climate change and sea-level rise also threatening these infrastructures. So, it is essential to identify suitable adaptation measures to prevent the failure of the present structures and strengthening the new structures to face future climate threats.",
    "links": [
      {
        "text": "Overview of the research",
        "url": "https://drive.google.com/file/d/1BN5EoQ1NDwgKWz6myJgtATb22AUYo0yG/view"
      },
      {
        "text": "Technical report",
        "url": "https://drive.google.com/file/d/1ybWsgaqAcKgqGImJ3qcYA76y6cG99eW1/view"
      },
    ]
  },
  {
    "title": "Risk and Vulnerability Assessment",
    "image": Images10,
    "content": "The Sendai Framework for Disaster Risk Reduction (2015-2030) has stressed the need for accountability at local, regional and national levels and the importance of addressing underlying drivers of disaster risk. Coastal Vulnerability Assessment along with the governance dimension acts as a strong tool for researchers and policymakers to make informed decisions. Protection of coastal infrastructure along with the regions is also essential for the economies of countries. An urgent requirement is to increase the susceptibility of this infrastructure and develop better methodologies for estimating vulnerability due to climate change. This research, therefore, attempts to translate the commonly understood conceptual attribute of governance into an empirically grounded analysis.",
    "links": [
      {
        "text": "Community based adaptation framework for rising sea level",
        "url": "https://drive.google.com/file/d/1-vGkje82vM4K2j5QqiIUofcUoaMm4J7G/view"
      },
      {
        "text": "Coastal vulnearbility index map for Chennai port",
        "url": "https://drive.google.com/file/d/1gmQS9WvI0-BzAFm57UDG0yG-JraB24rT/view"
      },
    ]
  },
];
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const Parllex = () => {

  return (
    <div>
      <header>{/* Your header content */}</header>
      <section className="bg-container">
        <img src={Images} alt="Card" />
        <div className="absolute-text">
          <h1 className="bounce" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            WELCOME{" "}
          </h1>
          <h2
            className="bounce"
            style={{
              display:'flex',
              textAlign:"center",
              color: "#fafafa",
              width:'80%',
              marginBottom: "573px",
              marginLeft:"90px",
              // marginLeft: "74px",
              fontFamily: "calibri",
              borderTopStyle: "groove",
              background: "rgba(0, 0, 0, 0.54)",
            }}
          >
            "The research is being carried out using numerical models, physical
            models and real-time data collection. The real-time data collected
            is used for validation of the models and also for future
            predictions."
          </h2>
        </div>
      </section>
      <Slider {...sliderSettings} style={{gap:"20px"}}>
         {cardData.map((card, index) => (
          <Card className="card" key={index} style={{ background: "#fafafa",fontFamily: "calibri" }}>
            <div>
              <img src={card.image} alt={`Card ${index + 1}`} />
              <div>
                <h2 style={{ textAlign: "center",fontSize:"31px",paddingTop:"18px",fontFamily: "calibri"  }}>{card.title}</h2>
                <p style={{ textAlign: "justify", margin: "10px",fontSize:"19px" ,fontFamily: "calibri" }}>
                  {card.content}
                </p>
                <div style={{ paddingBottom: "10px", paddingLeft: "300px" ,fontFamily: "calibri" }}>
                  {card.links && card.links.length > 0 && (
                    card.links.map((link, linkIndex) => (
                      <div key={linkIndex}>
                        <a
                          style={{ textDecoration: "none" }}
                          href={link.url}
                          target="_blank"
                        >
                          <p className="paragraph">{link.text}</p>
                        </a>
                        <br />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </Slider>
    </div>
  );
};

export default Parllex;
