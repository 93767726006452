import React from "react";
import "../Styles/footer.css"; // Import your CSS
import Icon1 from "../Images/Logo/IIT.png";
import Icon2 from "../Images/Logo/logo2.png";
import Icon3 from "../Images/Logo/IGCS.png";
const Footer = () => {
  return (
    <div className="Footer">
      <span className="Footer-social">
        <a target="_blank"  rel="noreferrer noopener" href="https://doe.iitm.ac.in/" className="github-button">
          <img src={Icon1} alt="IIT"  style={{ width: "100px" }}/>
        </a>
        <a href="#">
          <img
            src={Icon2}
            alt="logo"
            style={{
              width: "360px",
              marginLeft: "177px",
              marginRight: "145px",
            }}
          />
        </a>
        <a target="_blank"  rel="noreferrer noopener"  href="https://www.igcs-chennai.org/" className="twitter-share-button">
          <img src={Icon3} alt="logo" style={{ width: "100px" }} />
        </a>
      </span>
      <div className="Footer-credits">
        <span className="Footer-credit" style={{ fontFamily: "calibri" }}>
          © copyrights of all the contents in this website reserved to{" "}
          <a href="https://ccicias.in/" rel="noreferrer noopener"  style={{ fontFamily: "calibri" }}>
            DST-CoE-CCICIAS{" "}
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
