// Card.js
import React, { useState, useEffect } from "react";
import "../Styles/Card.scss";

const Card = ({ dataImage, children }) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  let mouseLeaveDelay;

  useEffect(() => {
    setWidth(document.getElementById("cards").offsetWidth);
    setHeight(document.getElementById("cards").offsetHeight);
  }, []);

  const handleMouseMove = (e) => {
    setMouseX(
      e.pageX - document.getElementById("cards").offsetLeft - width / 2
    );
    setMouseY(
      e.pageY - document.getElementById("cards").offsetTop - height / 2
    );
  };

  const handleMouseEnter = () => {
    clearTimeout(mouseLeaveDelay);
  };

  const handleMouseLeave = () => {
    mouseLeaveDelay = setTimeout(() => {
      setMouseX(0);
      setMouseY(0);
    }, 1000);
  };

  const cardStyle = {
    transform: `rotateY(${(mouseX / width) * -3}deg) rotateX(${
      (mouseY / height) * -5
    }deg)`,
  };

  const cardBgTransform = {
    transform: `translateX(${(mouseX / width) * 10}px) translateY(${
      (mouseY / height) * 10
    }px)`,
  };

  const cardBgImage = {
    backgroundImage: `url(${dataImage})`,
  };

  return (
    <div
      className="cards-wrap"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id="cards"
    >
      <div className="cards" style={cardStyle}>
        <div
          className="cards-bg"
          style={{ ...cardBgTransform, ...cardBgImage }}
        ></div>
        <div className="cards-info">{children}</div>
      </div>
    </div>
  );
};

export default Card;
