import React from "react";
import { HashRouter  as Router, Switch, Route } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./pages/home";
import Research from "./pages/Research";
import People from "./pages/People";
import Publications from "./pages/Publications";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import Presscoverage from "./pages/Presscoverage";
import Contact from "./pages/contact";
import Journalpapers from "./pages/Journalpapers";
import Footer from "./component/Footer";
import Parllex from "./component/Parllex";
import { ParallaxProvider } from "react-scroll-parallax";
function App() {
  return (
    <Router>
      <Navbar style={{ margin: "10px" }} />
      <ParallaxProvider>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Research" component={Research} />
        <Route path="/Parllex" component={Parllex} />
        <Route path="/People" component={People} />
        <Route path="/Publications" component={Publications} />
        <Route path="/Events" component={Events} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/Presscoverage" component={Presscoverage} />
        <Route path="/Contact" component={Contact} />
        <Route path="/Journalpapers" component={Journalpapers} />
      </Switch>
      </ParallaxProvider>
      <Footer />
    </Router>
  );
}
export default App;
