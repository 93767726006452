import React, { useRef, useState } from "react";
import "../Styles/Contactus.css";
import Img from "../Images/Contact/beautiful-ocean-pictures.jpg";
import Img2 from "../Images/Contact/istockphoto.jpg";
import { Card } from "react-bootstrap";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dtlrpxg",
        "template_k51dee2",
        form.current,
        "tLFMq0KgspvYxDeTx"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessModal(true);
          setTimeout(() => {
            setShowSuccessModal(false);
          }, 1000);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="containerscontact" >
      <div className="left-content">
        <Card
          style={{
            background: "linear-gradient(45deg, rgba(0,0,0,0.2), transparent)",
            backgroundImage: `url(${Img2})`,
            backgroundSize: "cover",
          }}
        >
          <h1
            className="header"
            style={{
              textAlign: "center",
              color: "#fff",
              letterSpacing: "12px",
            }}
          >
            About us
          </h1>
          <div
            style={{
              background: "rgba(0, 0, 0, 0.4)",
              border: "2px solid #000",
              margin: "10px",
              borderStyle: "groove",
            }}
          >
            <p
              style={{
                marginTop: "20px",
                textAlign: "justify",
                color: "#ffffff",
                fontSize: "21px",
                marginLeft: "37px",
                marginRight: "83px",
                marginBottom: "10px",
                textShadow: "2px 2px 7px rgba(0,0,0,0.2) ",
                fontWeight: "bold",
                padding: "10px",
              }}
            >
              Centre of Excellence - Climate Change Impacts on Coastal
              Infrastructure and Adaptation Strategies is established by DST,
              Ministry of Science and Technology, India at IIT Madras. The
              centre is mainly concentrated on research on the effect of climate
              change in the Indian region and to provide adaptation strategies
              considering future climate scenarios. The centre has 25 staff
              working on several climate models, lab experiments and field
              studies for real-time data. The staff are led by prominent
              professors from Ocean, Civil, Mechanical, Humanitarian Engineering
              departments of IIT Madras. The centre has its inauguration on 15th
              April 2019.
            </p>
          </div>
          <h1
            className="header"
            style={{
              textAlign: "center",
              color: "#fff",
              letterSpacing: "12px",
            }}
          >
            Contact us
          </h1>
          <div>
            <p
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "22px",
                letterSpacing: "4px",
                textTransform: "uppercase",
                fontWeight: "bold",
                textShadow: " rgba(0, 0, 0, 0.7) 2px 2px 7px",
                textDecoration: "underlines",
              }}
            >
              Get in touch so we can start working together!
            </p>

            <p
              style={{
                justifyContent: "center",
                textAlign: "justify",
                color: "#fff",
                fontSize: "20px",
                display: "flex",
                flexDirection: "row",
                textShadow: "rgb(0, 0, 0) 2px 2px 7px",
              }}
            >
              <p
                style={{
                  letterSpacing: "6px",
                  textTransform: "uppercase",
                  color: "#ffffff",
                  fontFamily: "calibri",
                }}
              >
                <i
                  style={{ color: "darkred" }}
                  class="fa fa-duotone fa-envelope-open-text"
                ></i>{" "}
                Email Us:
              </p>{" "}
              <a
                href="mailto:coe.climatechange@gmail.com"
                style={{
                  marginLeft: "20px",
                  fontSize: "23px",
                  color: "aquamarine",
                  fontWeight: "900",
                }}
              >
                coe.climatechange@gmail.com
              </a>
            </p>

            <p
              style={{
                justifyContent: "center",
                textAlign: "center",
                color: "#fff",
                fontSize: "20px",
                marginLeft: "-165px",
                display: "flex",
                flexDirection: "row",
                textShadow: "rgb(0, 0, 0) 2px 2px 7px",
                marginBottom: "60px",
              }}
            >
              <p
                style={{
                  letterSpacing: "3px",
                  textTransform: "uppercase",
                  color: "#ffffff",
                  fontFamily: "calibri",
                }}
              >
                <i
                  style={{ paddingRight: "8px" }}
                  class="fa fa-light fa-square-phone-flip fa-fa-blur-2xl"
                ></i>
                Contact Us:
              </p>{" "}
              <a
                style={{
                  marginLeft: "20px",
                  color: "aquamarine",
                  fontWeight: "900",
                }}
              >
                044-2257-8824
              </a>
            </p>
          </div>
        </Card>
      </div>
      <div className="background">
        <div>
          <form
            ref={form}
            onSubmit={sendEmail}
            style={{ backgroundImage: `url(${Img})`, backgroundSize: "cover" }}
          >
            <h3 className="loginheader">Connect Us</h3>
            <label>Name</label>
            <input
              type="text"
              name="user_name"
              placeholder="Full Name"
              required
            />
            <label>Email</label>
            <input
              type="email"
              name="user_email"
              placeholder="Email"
              required
            />
            <label>Message</label>
            <textarea
              name="message"
              placeholder="Type Something"
              required
              style={{ resize: "none" }}
            />
            <div class="fontuser">
              {" "}
              <input
                className="custom-button"
                type="submit"
                value="Send"
              />{" "}
              <i class="fa fa-paper-plane-o fa-2x"></i>{" "}
            </div>
          </form>
        </div>
        {showSuccessModal && (
          <div className="success-modal">
            <p style={{ display: "flex", flexDirection: "column" }}>
              {" "}
              <i className="fas fa-check-circle fa-4x"></i>Form submitted
              successfully!{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
