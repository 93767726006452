import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "../component/Card";
import "../Styles/sliderhome.css";
import "../Styles/Animation.css";
import Images2 from "../Images/HomePage/Landing2-min.jpg";
import Images3 from "../Images/HomePage/Landing1-min.jpg";
import Images4 from "../Images/HomePage/Landing4-min.jpg";
import ocean from "../Images/events/Event6.jpg";
import ocean1 from "../Images/events/Event2.jpg";
import main from "../Images/HomePage/Main-min.jpg";
import main2 from "../Images/HomePage/Main3-min.jpg";
import main3 from "../Images/HomePage/Main2-min.jpg";
import main4 from "../Images/HomePage/DST_IGCS_team_2019-min.jpg";
const cardData = [
  {
    id:1,
    title: "LandingImage",
    image: main,
  },
  // Add more cards as needed
  {
    id:2,
    title: "LandingImage1",
    image: main2,
  },
  {
    id:3,
    title: "LandingImage2",
    image: main3,
  },
  {
    id:4,
    title: "LandingImage",
    image: main4,
  },
  {
    id:5,
    title: "LandingImage2",
    image: Images2,
  },
  {
    id:6,
    title: "LandingImage3",
    image: Images3,
  },
];
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const Home = () => {
  return (
    <div>
      <Slider {...sliderSettings}>
        {cardData.map((card) => (
          <section key={card.id} style={{ display: "flex" }} className="bg-container">
            <div>
              <img src={card.image} alt="Card" />
              <div className="absolute-text">
                <h2
                  className="bounce"
                  style={{
                    fontSize:'26px',
                    width: "860px",
                    height: "90px",
                    color: "#ffffff",
                    marginBottom: "482px",
                    background:
                      "linear-gradient(182deg,rgb(78 201 233 / 40%), transparent)",
                    fontFamily: "calibri",
                    textAlign: "center",
                  }}
                >
                  Climate Change Impacts on Coastal Infrastructure and
                  Adaptation Strategies <br />
                  <p style={{ color: "#ffffff", fontFamily: "calibri" }}>
                    CCICIAS
                  </p>
                </h2>
              </div>
            </div>
          </section>
        ))}
      </Slider>

      <div
        className="container"
        style={{ paddingBottom: "40px", justifyContent: "space-around" }}
      >
        <Card dataImage={Images4}>
          <h1 style={{ paddingTop: "215px", fontFamily: "calibri" }}>
            About Centre
          </h1>
          <p style={{ fontFamily: "calibri" }}>
            {" "}
            The Centre of Excellence "Climate Change Impacts on Coastal
            Infrastructure and the Adaptation Strategies" was established with
            the support of the Department of Science and Technology,Government
            of India and Indio German Centre for Sustainability.The centre was
            launched by Prof.Bhaskar Ramamurthi, Former Director of IIT-Madras,
            in the presence of Dr.Akhilesh Gupta, Adviser & Head of SPLICE and
            Climate Change Program & Prof.S.K.Dubey,Former Director of
            IIT-Kharagpur on April 16,2019.
          </p>
        </Card>
        <Card dataImage={ocean}>
          <h1 style={{ paddingTop: "38px", fontFamily: "calibri" }}>
            Objective
          </h1>
          <p style={{ fontFamily: "calibri" }}>
            To understand the effect of climate change on the Indian Coastal
            regions and propose suitable adaptation measures for coastal
            infrastructure and water resources in order to overcome the effects
          </p>
        </Card>
        <Card dataImage={ocean1}>
          <h1 style={{ fontFamily: "calibri" }}>Funding</h1>
          <p style={{ fontFamily: "calibri" }}>
            This research is funded by Department of Science & Technology under
            SPLICE - Climate Change Program (DST/CCP/CoE/141/2018(C)) and supported by Indo German Centre for Sustainability (IGCS).
          </p>
        </Card>

        {/* Add more Card components as needed */}
      </div>
    </div>
  );
};

export default Home;
